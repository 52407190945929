import React from 'react';
import Card from './PageCard';

export default function TestPage() {
  console.log('Test Page');
  return (
    <Card>
      <p>test page</p>
    </Card>
  );
}