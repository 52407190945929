import React from 'react';
import Card from '../PageCard';

export default function Dashboard() {
  return (
    <Card>
      <h2>Dashboard</h2>
      <span style={{float: 'right'}}><i>test</i></span>
      <hr />
      
      <span>something..</span>
    </Card>
  );
}